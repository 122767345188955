.adm-tag {
  --border-radius: var(--adm-tag-border-radius, 0.053333rem);
  padding: 0.08rem 0.133333rem;
  font-size: 0.32rem;
  line-height: 1;
  font-weight: normal;
  background: var(--background-color);
  color: var(--text-color);
  display: inline-block;
  white-space: nowrap;
  border-radius: var(--border-radius);
  border: 0.013333rem solid var(--border-color);
  box-sizing: border-box;
}

.adm-tag-round {
  border-radius: 2.666667rem;
}