.adm-action-sheet-popup > .adm-popup-body {
  border-top-left-radius: 0.213333rem;
  border-top-right-radius: 0.213333rem;
  overflow: hidden;
}

.adm-action-sheet-extra {
  display: flex;
  justify-content: center;
  color: var(--adm-color-weak);
  font-size: 0.4rem;
  padding: 0.48rem 0.426667rem;
  border-bottom: 0.026667rem solid var(--adm-border-color);
}

.adm-action-sheet-button-item-wrapper {
  background-color: #ffffff;
  border-top: 0.026667rem solid var(--adm-border-color);
}

.adm-action-sheet-button-item-wrapper:first-child {
  border-top: none;
}

.adm-action-sheet-button-item {
  padding: 0.373333rem;
  text-align: center;
  border-radius: 0;
}

.adm-action-sheet-button-item-name {
  color: var(--adm-color-text);
  font-size: 0.48rem;
}

.adm-action-sheet-button-item-description {
  font-size: 0.32rem;
  color: var(--adm-color-weak);
  padding-top: 0.106667rem;
}

.adm-action-sheet-button-item-danger .adm-action-sheet-button-item-name {
  color: var(--adm-color-danger);
}

.adm-action-sheet-cancel {
  background-color: #f5f5f5;
  padding-top: 0.213333rem;
}