.adm-badge-wrap {
  display: inline-block;
  position: relative;
}

.adm-badge {
  display: inline-block;
  color: #fff;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  min-width: 0.426667rem;
  border-radius: 2.666667rem;
  padding: 0 0.106667rem;
  font-size: 0.24rem;
  line-height: 1.2;
  white-space: nowrap;
  --right: 0;
  --top: 0;
}

.adm-badge-fixed {
  position: absolute;
  right: var(--right);
  top: var(--top);
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.adm-badge-dot {
  min-width: 0.266667rem;
  width: 0.266667rem;
  height: 0.266667rem;
  border-radius: 0.133333rem;
}