.adm-tab-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  min-height: 1.333333rem;
}

.adm-tab-bar-item {
  flex: 1 1;
  color: var(--adm-color-weak);
  white-space: nowrap;
  padding: 0.106667rem 0.213333rem;
  width: -webkit-min-content;
  width: min-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.adm-tab-bar-item-icon {
  font-size: 0.533333rem;
  height: 0.586667rem;
  line-height: 1;
  margin: 0.106667rem 0 0.026667rem;
}

.adm-tab-bar-item-title {
  font-size: 0.293333rem;
  line-height: 0.4rem;
}

.adm-tab-bar-item-active {
  color: var(--adm-color-primary);
}

.adm-tab-bar-icon-badge {
  --top: 0.16rem;
}

.adm-tab-bar-title-badge {
  --right: -0.053333rem;
  --top: -0.053333rem;
}