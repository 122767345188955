.adm-list {
  --prefix-width: 'auto';
  --align-items: center;
  --active-background-color: var(--adm-border-color);
  --border-inner: solid 0.026667rem var(--adm-border-color);
  --border-top: solid 0.026667rem var(--adm-border-color);
  --border-bottom: solid 0.026667rem var(--adm-border-color);
  --padding-left: 0.32rem;
  background-color: #ffffff;
  overflow: hidden;
  font-size: 0.453333rem;
}

.adm-list-default {
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
}

.adm-list-card {
  margin: 0.32rem;
  border-radius: 0.213333rem;
}

.adm-list-item {
  margin-bottom: -0.026667rem;
  display: block;
  padding-left: var(--padding-left);
}

.adm-list-item:not(:first-child) {
  padding-top: 0.026667rem;
}

.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: 0.4rem;
}

.adm-list-item-content {
  display: flex;
  align-items: var(--align-items);
  justify-content: flex-start;
  padding: 0.32rem 0.32rem 0.32rem 0;
  border-bottom: var(--border-inner);
}

.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: 0.32rem;
}

.adm-list-item-content-main {
  flex: auto;
}

.adm-list-item-content-extra {
  flex: none;
  padding-left: 0.32rem;
}

.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 0.32rem;
  color: var(--adm-color-weak);
  font-size: 0.426667rem;
}

.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

a.adm-list-item:active {
  background-color: var(--active-background-color);
}

a.adm-list-item.adm-list-item-disabled:active {
  background-color: unset;
}