.adm-cascader {
  width: 100%;
  height: 10.666667rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.adm-cascader-header {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0.213333rem;
}

.adm-cascader-header-button {
  font-size: 0.4rem;
  display: inline-block;
  padding: 0.106667rem 0.106667rem;
}

.adm-cascader-header-title {
  padding: 0.106667rem 0.106667rem;
  font-size: 0.4rem;
  color: var(--adm-color-text);
  text-align: center;
  flex: 1 1;
}

.adm-cascader-body {
  flex: auto;
  height: 100%;
  width: 100%;
}

.adm-cascader-body > .adm-cascader-view {
  --height: 8.266667rem;
}