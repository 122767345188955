.adm-toast-mask .adm-toast-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 2.56rem;
  max-width: 70%;
  max-height: 70%;
  overflow: auto;
  color: white;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.213333rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: all;
  font-size: 0.4rem;
  line-height: 1.5;
  box-sizing: border-box;
}

.adm-toast-mask .adm-toast-wrap-text {
  padding: 0.32rem;
}

.adm-toast-mask .adm-toast-wrap-icon {
  padding: 0.933333rem 1.066667rem;
}

.adm-toast-mask .adm-toast-wrap-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 0.213333rem;
  font-size: 0.96rem;
  line-height: 1;
}

.adm-toast-loading {
  font-size: 0.64rem;
}