.adm-cascader-view {
  --height: auto;
}

.adm-cascader-view-tabs.adm-tabs {
  --title-font-size: 0.373333rem;
  --content-padding: none;
}

.adm-cascader-view-header-title {
  max-width: 2.24rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.adm-cascader-view-content {
  height: var(--height);
  padding-top: 0.106667rem;
  box-sizing: border-box;
}

.adm-cascader-view-content.adm-list {
  --border-inner: none;
  --border-bottom: none;
  --border-top: none;
  overflow-y: auto;
}

.adm-cascader-view-item {
  font-size: 0.373333rem;
}

.adm-cascader-view-item-active {
  color: var(--adm-color-primary);
}

.adm-cascader-view .adm-list-inner {
  margin-bottom: 0;
}