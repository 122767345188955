.adm-picker-view {
  --height: 6.4rem;
  height: var(--height);
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  background: var(--adm-color-white);
}

.adm-picker-view-column {
  height: 100%;
  flex: 1 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  position: relative;
  z-index: 0;
}

.adm-picker-view-column-wheel {
  width: 100%;
  cursor: grab;
  position: absolute;
  top: calc(50% - 0.453333rem);
  left: 0;
}

.adm-picker-view-column-wheel::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -100vh;
}

.adm-picker-view-column-wheel::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  bottom: -100vh;
}

.adm-picker-view-column-item {
  font-size: 0.426667rem;
  padding: 0 0.16rem;
  height: 0.906667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adm-picker-view-column-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adm-picker-view-column-accessible {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.adm-picker-view-column-accessible > * {
  flex: 1 1;
  text-overflow: ellipsis;
}

.adm-picker-view-column-accessible-current {
  position: absolute;
  width: 100%;
  height: 100%;
}

.adm-picker-view-column-accessible-button {
  width: 100%;
  height: 100%;
}

.adm-picker-view-mask {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.adm-picker-view-mask-top,
.adm-picker-view-mask-bottom {
  flex: auto;
}

.adm-picker-view-mask-middle {
  height: 0.906667rem;
  box-sizing: border-box;
  flex: none;
  border-top: solid 0.026667rem var(--adm-border-color);
  border-bottom: solid 0.026667rem var(--adm-border-color);
}

.adm-picker-view-mask-top {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 50%, #ffffff);
}

.adm-picker-view-mask-bottom {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 50%, #ffffff);
}