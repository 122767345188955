.adm-picker {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.adm-picker-header {
  flex: none;
  border-bottom: solid 0.026667rem var(--adm-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0.213333rem;
}

.adm-picker-header-button {
  font-size: 0.4rem;
  display: inline-block;
  padding: 0.106667rem 0.106667rem;
}

.adm-picker-header-title {
  padding: 0.106667rem 0.106667rem;
  font-size: 0.4rem;
  color: var(--adm-color-text);
  text-align: center;
  flex: 1 1;
}

.adm-picker-body {
  flex: auto;
  height: 100%;
  width: 100%;
}

.adm-picker-body > .adm-picker-view {
  --height: 100%;
}