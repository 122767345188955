.adm-dialog {
  --z-index: var(--adm-dialog-z-index, 1000);
  position: fixed;
  z-index: var(--z-index);
}

.adm-dialog .adm-dialog-mask {
  z-index: 0;
}

.adm-dialog-wrap {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 75vw;
  max-width: 13.333333rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.adm-dialog-main {
  width: 100%;
  font-size: 0.373333rem;
  background-color: white;
  border-radius: 0.213333rem;
  overflow: hidden;
}

.adm-dialog-main .adm-dialog-body {
  padding: 0.533333rem 0.32rem;
}

.adm-dialog-main .adm-dialog-body-header-wrapper {
  display: flex;
  justify-content: center;
}

.adm-dialog-main .adm-dialog-body-title {
  font-weight: bold;
  font-size: 0.48rem;
  line-height: 0.666667rem;
  text-align: center;
}

.adm-dialog-main .adm-dialog-body-content {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0.4rem;
  line-height: 1.4;
  color: #333;
}

.adm-dialog-main .adm-dialog-footer {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row {
  display: flex;
  align-items: stretch;
  border-top: 0.013333rem solid var(--adm-border-color);
}

.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > * {
  flex: 1 1;
}

.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  padding: 0.266667rem;
  font-size: 0.48rem;
  line-height: 0.666667rem;
  border-radius: 0;
  border-right: solid 0.013333rem var(--adm-border-color);
}

.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button-bold {
  font-weight: bold;
}

.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button:last-child {
  border-right: none;
}