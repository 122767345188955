.adm-index-bar {
  --color: var(--adm-color-text);
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: var(--adm-color-white);
  --sticky-offset-top: 0;
}

.adm-index-bar-body {
  overflow: scroll;
  height: 100%;
  width: 100%;
}

.adm-index-bar-body::-webkit-scrollbar {
  display: none;
}

.adm-index-bar-anchor-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0.933333rem;
  padding: 0 0.32rem;
  color: var(--adm-color-weak);
  font-size: 0.346667rem;
  background-color: #f5f5f5;
}

.adm-index-bar-sidebar {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
  overflow: visible;
  color: var(--adm-color-weak);
  font-size: 0.32rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.adm-index-bar-sidebar-bubble {
  position: absolute;
  top: 50%;
  right: 1.12rem;
  width: 1.253333rem;
  height: 1.253333rem;
  color: var(--adm-color-white);
  font-size: 0.666667rem;
  line-height: 1.253333rem;
  text-align: center;
  background: var(--adm-color-light);
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.adm-index-bar-sidebar-row {
  cursor: pointer;
  width: 100%;
  text-align: right;
  position: relative;
  padding: 0 0.32rem;
}

.adm-index-bar-sidebar-row > * {
  pointer-events: none;
}

.adm-index-bar-sidebar-item {
  display: inline-flex;
  width: 0.426667rem;
  height: 0.426667rem;
  line-height: 0.426667rem;
  justify-content: center;
  align-items: center;
}

.adm-index-bar-sidebar-item-active {
  color: #fff;
  background-color: var(--adm-color-primary);
  border-radius: 50%;
}

.adm-index-bar-sidebar-interacting {
  width: 100%;
}

.adm-index-bar-sticky .adm-index-bar-anchor-title {
  position: -webkit-sticky;
  position: sticky;
  top: var(--sticky-offset-top);
  left: 0;
}